import React from "react";

import Image from "next/image";
import Link from "next/link";

import { SlideProps } from "./types";

import styles from "./styles.module.scss";

const Slide = ({ slide, onClick }: SlideProps) => (
  <article className={styles.videoCard}>
    <figure className={styles.videoCardCover}>
      <Link passHref href={slide.link} prefetch={false}>
        <a onClick={onClick}>
          <Image
            alt={slide.name}
            height={200}
            layout="intrinsic"
            loading="lazy"
            objectFit="cover"
            src={slide.cover.sizes.medium_large}
            width={267}
          />
        </a>
      </Link>
    </figure>
    <div className={styles.videoCardContent}>
      <Link passHref href={slide.link} prefetch={false}>
        <a onClick={onClick}>
          <div className={styles.videoCardType}>{slide.category}</div>
          <div className={styles.videoCardName}>{slide.name}</div>
        </a>
      </Link>
    </div>
  </article>
);

export default Slide;
