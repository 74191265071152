import React, { FC, useCallback, useMemo, useState } from "react";

import dynamic from "next/dynamic";

import { Account_Api_EntryPointEnum } from "@/graphql-schema-types.generated";
import { useSetEntryPoint } from "@/lib/saveEntryPoint";

import { TitleLink } from "../TitleLink";
import Slide from "./Slide";
import { WebinarsAndPodcastsProps } from "./types";

import styles from "./styles.module.scss";

const Slider = dynamic(() => import("./Slider"), {
  ssr: false,
});

const WebinarsAndPodcasts: FC<WebinarsAndPodcastsProps> = ({ content }) => {
  const { setEntryPoint } = useSetEntryPoint();
  const videos = content.videos;
  const [showMore, setShowMore] = useState(false);

  const mobileVideos = useMemo(
    () => videos.slice(0, showMore ? 8 : 4),
    [videos, showMore]
  );

  const handleShowMore = useCallback(() => setShowMore(true), []);

  const handleSaveEntrypoint = useCallback(
    () => setEntryPoint(Account_Api_EntryPointEnum.EntryPointWebinar),
    [setEntryPoint]
  );

  return (
    <section>
      <div className={styles.header}>
        <TitleLink href={content.button_url}>{content.title}</TitleLink>
      </div>
      <Slider handleSaveEntrypoint={handleSaveEntrypoint} videos={videos} />
      <div className={styles.container}>
        {mobileVideos.map((v) => (
          <div key={v.name} className={styles.post}>
            <Slide onClick={handleSaveEntrypoint} slide={v} />
          </div>
        ))}
        {!showMore && videos.length > 4 && (
          <button
            className={styles.showMore}
            onClick={handleShowMore}
            type="button"
          >
            Show more
          </button>
        )}
      </div>
    </section>
  );
};

export default WebinarsAndPodcasts;
